
.logo-wrapper {position: absolute; z-index: 1; top: 10px; left: 10px; width: 150px; height: 150px;}
.logo-image {flex: 1; font-size: 24px; font-weight: 700;}
.logo-title {flex: 1; font-size: 24px; font-weight: 700;}

.logo-wrapper img {width: 100%; height: 100%; border-radius: 30px; opacity: 0.9; border: 4px solid #AAAAAADD;}

.help-tip {position: absolute; z-index: 2; left: 300px; width: 400px; height: 60px; top: -80px; background-color: #FFFFFFEE; padding: 10px 20px; transition: all 0.3s;}
.help-tip.show {top: 0;}
.help-tip .help-label {color: black; width: 100%;}

.logo-wrapper.hide {display: none;}

.mobile .logo-wrapper {width: 120px; height: 75px;}
.mobile .logo-wrapper div {font-size: 20px; font-weight: 500; user-select: none;}

.mobile .help-tip {left: 150px; width: 115px; height: 40px; border-radius: 8px; border: 1px solid;}
.mobile .help-tip.show {top: 10px;}