body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


body { overflow: hidden; margin: 0; padding: 0; }
.page-wrapper { width: 100vw; height: 100vh; background-position: center; overflow: hidden; }
.back-board { position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; opacity: 0; z-index: -1; overflow: hidden; background-color: white;}

.back-board.active { opacity: 1; z-index: 1; }

.flex {display: flex; align-items: center; justify-content: center;}
.flex-column {display: flex; flex-direction: column; align-items: center; justify-content: center;}

.loading { background-color: rgba(0, 0, 0, 0.5); opacity: 0; transition: all 0.5s;}
.loading.show { opacity: 1; z-index: 3;}
.loading .loading-circle { position: absolute; top: calc(50% - 60px); left: calc(50% - 60px); border: 16px solid #f3f3f3; border-top: 16px solid #3498db; border-radius: 50%; width: 90px; height: 90px; -webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite; }
.loading .loading-circle.grey { border: 6px solid #444; border-top: 6px solid white; }

.loading .loading-label {font-size: 28px; color: white;}

@-webkit-keyframes spin {
	0%   { -webkit-transform: rotate(  0deg); transform: rotate(  0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
	0%   { -webkit-transform: rotate(  0deg); transform: rotate(  0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-webkit-keyframes arrowAnimate {
	0% 	 { -webkit-transform:translateX( 30px); transform:translateX( 30px); }
	100% { -webkit-transform:translateX(-30px); transform:translateX(-30px); }
}
@keyframes arrowAnimate {
	0% 	 { -webkit-transform:translateX( 30px); transform:translateX( 30px); }
	100% { -webkit-transform:translateX(-30px); transform:translateX(-30px); }
}

.title {font-size: 20px; font-weight: 700;}
.big-title { font-size: 24px; font-weight: 700;}
.label { font-size: 14px;}
.capital {text-transform: capitalize;}

.button {background-color: #108CCA; font-size: 20px; border-radius: 8px; padding: 10px 30px; transition: all 0.2s; color: white; margin: 20px; cursor: pointer; text-align: center;}
.button.disable {cursor: default; opacity: 0.5;}
.button:hover {background-color: #123bc2;}

.scroll-y { padding-right: 5px; overflow-y: auto; overflow-x: hidden;}
.scroll-x { padding-bottom: 5px; overflow-x: auto; overflow-y: hidden;}
.scroll::-webkit-scrollbar { width: 6px; height: 3px; }
.scroll::-webkit-scrollbar-track { background: #DCDEDF; }
.scroll::-webkit-scrollbar-thumb { background: rgba(0, 0, 0, 0.8); }
.scroll::-webkit-scrollbar-thumb:hover { background: #BBB; }

.close-icon { position: absolute; top: 15px; right: 15px; width: 25px; height: 25px; border: 1px solid #525047; border-radius: 50%; cursor: pointer; background-color: rgba(255, 255, 255, 0.8);}
.close-icon svg { width: 13px; height: 13px; transition: -webkit-transform 0.3s; transition: transform 0.3s; transition: transform 0.3s, -webkit-transform 0.3s;}
/* .close-icon svg path { stroke: #525047;} */
.close-icon:hover svg { -webkit-transform: rotate(-90deg); transform: rotate(-90deg); }

.size-alert {position: absolute; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 2; background-color: #FFFFFFDD;}


.logo-wrapper {position: absolute; z-index: 1; top: 10px; left: 10px; width: 150px; height: 150px;}
.logo-image {flex: 1 1; font-size: 24px; font-weight: 700;}
.logo-title {flex: 1 1; font-size: 24px; font-weight: 700;}

.logo-wrapper img {width: 100%; height: 100%; border-radius: 30px; opacity: 0.9; border: 4px solid #AAAAAADD;}

.help-tip {position: absolute; z-index: 2; left: 300px; width: 400px; height: 60px; top: -80px; background-color: #FFFFFFEE; padding: 10px 20px; transition: all 0.3s;}
.help-tip.show {top: 0;}
.help-tip .help-label {color: black; width: 100%;}

.logo-wrapper.hide {display: none;}

.mobile .logo-wrapper {width: 120px; height: 75px;}
.mobile .logo-wrapper div {font-size: 20px; font-weight: 500; -webkit-user-select: none; user-select: none;}

.mobile .help-tip {left: 150px; width: 115px; height: 40px; border-radius: 8px; border: 1px solid;}
.mobile .help-tip.show {top: 10px;}

.canvas { position: fixed; width: 100vw; height: 100vh; z-index: -1; transition: left 0.5s; }

#testCanvas { position: absolute; top: 0; left: 0; z-index: -1;}

#resetImage {position: absolute; width: 1000px; height: 1000px; z-index: -1; background-color: white;}

.setting {position: absolute; top: 20px; left: 20px;}
.setting select {font-size: 20px;}
.setting .set-item {padding: 5px 20px; border: 1px solid; border-radius: 5px; cursor: pointer; margin: 15px; background-color: white;}
.setting .set-item .set-title {margin-bottom: 5px; font-size: 20px; font-weight: 700;}
.setting .set-item .set-line {display: flex; margin: 5px 0px;}
.setting .set-item .set-line .set-label {width: 60px;}
.setting .set-item .set-line input {flex: 1 1;}

.btn {margin: 15px; padding: 5px 20px; border-radius: 5px; cursor: pointer; border: 1px solid; background-color: white;}

.test-frame {width: 80vh; height: 80vh; border: 1px solid; position: fixed; top: 10vh;}


.side-bar {position: absolute; top: 0; height: 100vh; width: 250px; padding: 0px 10px; background-color: #FFFFFFDD; z-index: 1; transition: all 0.5s;}
.side-bar .side-show-wrapper {position: relative; width: 100%; height: 10px;}
.side-bar .side-show-wrapper .side-show-inner {position: absolute; width: 20px; height: 80px; background-color: #FFFFFFDD; top: 0; cursor: pointer; transition: all 0.5s;}
.side-bar.show .side-show-wrapper .side-show-inner {background-color: #FFFFFF00;}
.side-bar .side-show-wrapper .side-show-inner img {width: 15px; height: 40px; transition: all 0.5s; opacity: 0.7;}
.side-bar.show .side-show-wrapper .side-show-inner img {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
.side-bar .logo-title { width: 100%; height: 36px; padding: 20px 0px; border-bottom: 2px solid #AAAAAA; font-size: 30px; font-weight: 700;}


.side-bar .main-item {width: 100%;}
.side-bar .main-item .title-row {display: flex; align-items: center; width: 100%; margin: 7px 0px; padding: 7px 0px; border-bottom: 1px solid #AAAAAA; border-top: 1px solid #AAAAAA; cursor: pointer;}
.side-bar .main-item .title-row label {font-size: 18px; font-weight: 700; width: calc(100% - 20px); cursor: pointer;}
.side-bar .main-item .title-row img {width: 8px; height: 16px; opacity: 0.8; transition: all 0.5s;}
.side-bar .main-item.open .title-row label {color: #0000FF;}
.side-bar .main-item.open .title-row img {-webkit-transform: rotate(90deg);transform: rotate(90deg);}

.side-bar .main-item .sub-wrapper {height: 0; overflow: hidden; transition: all 0.5s; position: relative;}
.side-bar .sub-wrapper {height: 0; overflow: hidden;}
.side-bar .sub-wrapper .sub-title {font-size: 14px; font-weight: 700; margin-left: 15px;}
.side-bar .sub-item {margin: 10px 0px; margin-left: 20px; width: calc(100% - 20px); font-size: 16px; display: flex; align-items: center;}
.side-bar .sub-item label {font-size: 16px;}
.side-bar .sub-item.input-item label {width: 60px; margin-right: 10px;}
.side-bar .sub-item.check-item label {margin-left: 10px;}
.side-bar .sub-item.input-item input {flex: 1 1;}
.side-bar .sub-item.input-item .unit-label {width: 45px; margin-left: 5px; font-size: 12px; text-align: right;}

.side-bar .empty-row {width: 100%; height: 10px;}
.side-bar .sub-item .model-button {border-radius: 4px; border: 1px solid; margin-left: 10px; padding: 0px 10px; width: 120px; background-color: white; height: 30px; cursor: pointer; transition: all 0.3s; display: flex; align-items: center;}
.side-bar .sub-item .model-button:hover {-webkit-transform: scale(1.08);transform: scale(1.08);}
.side-bar .sub-item .model-button label {flex: 1 1; cursor: pointer;}
.side-bar .sub-item .model-button:hover label {color: #0000FF;}
.side-bar .sub-item .model-button img {width: 27px; height: 27px;}
.side-bar .sub-item label.add-count {margin-left: 10px; color: red;}
.side-bar .sub-item .radio-wrapper {display: flex; justify-content: center; align-items: center; border: 1px solid white; height: 40px; padding: 3px 3px 3px 0px; cursor: pointer; flex: 1 1;}
.side-bar .sub-item .radio-wrapper.first {margin-left: 10px;}
.side-bar .sub-item .radio-wrapper.active {border-color: blue;}
.side-bar .sub-item .model-dir {width: 10px; height: 30px; border: 1px solid; background-color: grey; display: flex; align-items: center; justify-content: center;}
.side-bar .sub-item .model-dir.dir-hor {width: 30px; height: 10px;}
.side-bar .sub-item .model-dir .inner-circle {display: none; width: 6px; height: 6px; border-radius: 3px; background-color: black;}
.side-bar .sub-item.sub-input-rotate-sump .model-dir .inner-circle {display: block;}
.side-bar .sub-item.sub-input-rotate-sump .model-dir.dir-ver-0 .inner-circle {margin-bottom: 15px;}
.side-bar .sub-item.sub-input-rotate-sump .model-dir.dir-hor-1 .inner-circle {margin-right: 15px;}
.side-bar .sub-item.sub-input-rotate-sump .model-dir.dir-ver-2 .inner-circle {margin-top: 15px;}
.side-bar .sub-item.sub-input-rotate-sump .model-dir.dir-hor-3 .inner-circle {margin-left: 15px;}
.side-bar .sub-item input[type="radio"] {flex: none; margin: 0px 5px; width: 50%;}

.side-bar .main-import-sump .sub-wrapper .sub-item .radio-wrapper {flex: 1 1; width: 100%; justify-content: flex-start;}
.side-bar .main-import-sump .sub-wrapper .sub-item .radio-wrapper input {width: 50%;}
.side-bar .main-import-sump .sub-wrapper .sub-item.hide {display: none;}

/* sub wrapper height */
.side-bar .main-building-size.open .sub-wrapper {height: 74px;}
.side-bar .main-import-sump.open .sub-wrapper {height: 120px;}
.side-bar .main-add-element.open .sub-wrapper {height: 420px;} /* 260px */
.side-bar .main-price-table.open .sub-wrapper {height: 560px;}
.side-bar .main-environment.open .sub-wrapper {height: 100px;}
.side-bar .main-objectList.open .sub-wrapper {height: calc(100vh - 550px);}
.side-bar .main-selObject.open .sub-wrapper {height: 160px;}
.side-bar .main-chooseVegetable.open .sub-wrapper {height: 480px;}

/* price table */
.side-bar .price-table {margin-left: 0px; border-left: 1px solid #AAAAAA;}
.side-bar .price-table .price-row {display: flex; border-bottom: 1px solid #AAAAAA;}
.side-bar .price-table .price-row .price-field {display: flex; font-size: 14px; padding: 8px 0px; align-items: center; justify-content: center; text-align: center; border-right: 1px solid#AAAAAA; word-break: break-all;}
.side-bar .price-table .price-row .price-field.field-object {width: 74px;}
.side-bar .price-table .price-row .price-field.field-count {width: 45px;}
.side-bar .price-table .price-row .price-field.field-size {width: 50px;}
.side-bar .price-table .price-row .price-field.field-amount {width: 50px;}
.side-bar .price-table .price-row .price-field.field-total {width: 50px;}
.side-bar .price-table .price-row .price-field.field-delete {width: 30px; flex: 1 1;}
.side-bar .price-table .price-row .price-field.field-delete.active {cursor: pointer;}
.side-bar .price-table .price-row .price-field.field-delete img {width: 20px;}
.side-bar .price-table .price-row .price-field.field-num {justify-content: right; padding-right: 5px; width: 45px;}
.side-bar .price-table .price-row .price-field.field-num.field-total {font-weight: 700;}
.side-bar .price-table .price-row.price-total-row { background-color: #DDDDDDFF;}
.side-bar .price-table .price-row.price-total-row div {font-size: 16px; font-weight: 700; color: #000000;}

.side-bar .total-price-wrapper {margin: 20px 0px;}
.side-bar .total-price-wrapper div { flex: 1 1; text-align: center; font-size: 16px; font-weight: 700; color: #0000ff;}

.side-bar .price-table .price-row .price-field.field-initType {flex: 3 1;}
.side-bar .price-table .price-row .price-field.field-initPrice {flex: 2 1;}

.side-bar .price-table .price-row.price-header {margin-top: 10px; border-top: 1px solid #AAAAAA;}
.side-bar .price-table .price-row.price-header .price-field {font-weight: 700; justify-content: center;}

.side-bar .main-price-table .total-price {margin-top: 10px; font-weight: 700; text-align: right;}

.side-bar .main-selObject .sub-wrapper .object-delete {position: absolute; top: 10px; right: 10px; width: 40px; height: 40px; border: 1px solid; border-radius: 4px; cursor: pointer;}
.side-bar .main-selObject .sub-wrapper .object-delete img {width: 30px;}


.side-left {left: -270px; width: 250px;}
.side-left.show {left: 0;}
.side-left .side-show-wrapper .side-show-inner {left: calc(100% + 10px); border-left: 1px solid #AAAAAA;}
.side-left.show .side-show-wrapper .side-show-inner {left: calc(100% - 12px);}



/* .side-right {right: -340px; width: 320px;} */
.side-right {right: -320px; width: 300px;}
.side-bar.side-right .side-show-wrapper .side-show-inner {width: 40px;}
.side-bar.side-right .side-show-wrapper .side-show-inner img {-webkit-transform: rotate(180deg);transform: rotate(180deg);width: auto;}
.side-bar.side-right.show .side-show-wrapper .side-show-inner img {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
.side-right.show {right: 0 !important;}

.side-right .logo-title .left-wrapper {flex: 1 1;}

.side-right .logo-title .icon-wrapper {margin-right: 15px; cursor: pointer; transition: all 0.3s; opacity: 0.8; display: flex; align-items: center; justify-content: center;}
.side-right .logo-title .icon-wrapper img {width: 100%; height: 100%;}
.side-right .logo-title .icon-wrapper:hover {-webkit-transform: scale(1.1);transform: scale(1.1); opacity: 1;}

.side-right .logo-title .help-wrapper {width: 30px; height: 30px; border-radius: 50%; border: 2px solid;}
.side-right .logo-title .help-wrapper label {font-size: 20px; font-weight: 700; cursor: pointer;}

.side-right .logo-title .reset-wrapper {width: 35px; height: 35px;}
.side-right .logo-title .camera-wrapper {width: 42px; height: 32px;}

.side-right .side-show-wrapper .side-show-inner {left: -48px; border-right: 1px solid #AAAAAA;}
.side-right.show .side-show-wrapper .side-show-inner {left: -10px;}

.side-right      .side-show-wrapper .side-show-inner:hover img {margin-right: 15px;}
.side-right.show .side-show-wrapper .side-show-inner:hover img {margin-right: -15px;}

/* sub wrapper height */

.object-list-wrapper {margin-left: 10px; width: calc(100% - 11px); height: calc(100vh - 550px); border-top: 1px solid #888888;}
.object-list-wrapper * {font-size: 14px;}
.object-list-wrapper .object-row {display: flex; border-bottom: 1px solid #888888; height: 30px;}
.object-list-wrapper .object-row.active {background-color: #88DDFF;}
.object-list-wrapper .object-row div {display: flex; justify-content: center; align-items: center; border-right: 1px solid #888888; padding: 5px 0px;}
.object-list-wrapper .object-row div:first-child {border-left: 1px solid #888888;}
.object-list-wrapper .object-list-header * {font-weight: 700;}
.object-list-wrapper .object-row .cell-no {width: 20px;  cursor: pointer;}
.object-list-wrapper .object-row .cell-name {width: 90px; cursor: pointer;}
.object-list-wrapper .object-row .cell-pos {width: 85px; cursor: pointer;}
.object-list-wrapper .object-row .cell-pos label {flex: 1 1; margin-left: 5px;}
.object-list-wrapper .object-row .cell-rot {width: 40px; cursor: pointer;}
.object-list-wrapper .object-row .cell-del {width: 30px; cursor: pointer;}
.object-list-wrapper .object-row img {width: 20px; height: 20px;}

.sub-wrapper .sub-interval {clear: both;}
.main-chooseVegetable .sub-wrapper .sub-title {margin-top: 10px;}
.crop-wrapper {display: inline;}
.crop-wrapper .button {background: none; color: black; font-size: 16px; border: 1px solid #666; margin: 4px 4px; padding: 8px 9px; font-weight: 500; border-radius: 4px; display: inline-block; transition: all 0.3s; background-color: white;}
.crop-wrapper .button * {cursor: pointer;}
.crop-wrapper .button:hover{border-color: blue; color: blue;}
.crop-wrapper .button.active{border-color: red; color: red;}
.crop-wrapper .button img {width: 30px; height: 30px;}

.fish-wrapper {width: 100%;}
.fish-wrapper .fish-item {flex: 1 1; margin: 15px 10px 0px 10px; text-align: center; border-bottom: 1px solid #666; padding-bottom: 3px; background-color: white; padding: 0px 10px;}
.fish-wrapper .fish-item.active {border-color: red; color: red;}
.fish-wrapper .fish-item img  {height: 50px; margin-left: 10px;}

/* .side-footer */



.mobile .side-bar.side-right {width: calc(100vw - 20px); right: -100vw;}
.mobile .side-bar.side-right {width: calc(100vw - 20px); right: -100vw;}
.mobile .side-footer {margin-bottom: 70px;}
/* .mobile .side-bar.side-right.show {width: calc(100vw - 20px); right: -100vw;} */

.modal-back {position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; z-index: -1; display: flex; align-items: center; justify-content: center; overflow: hidden;}
.modal-back.active {background-color: rgba(0, 0, 0, 0.2); z-index: 3!important;}
.modal-back .modal-wrapper {position: relative; padding: 20px; transition: all 0.5s; opacity: 0; box-shadow: 0px 3px 6px #00000029; background-color: #FFFFFF;}
.modal-back .modal-wrapper.active { margin: 0px; opacity: 1; -webkit-transform:scale(1); transform:scale(1);}
.modal-back .modal-wrapper .button {width: 120px;}

/* .modal-wrapper * {font-size: 16px; color: #525047;} */
.modal-wrapper .modal-title { text-align: center; font-size: 24px; margin-bottom: 12px; min-height: 24px; font-weight: 700;}
.modal-wrapper .content {margin-top: 20px;}

.detail-modal .modal-wrapper { flex-direction: column; width: 760px; margin-top: 50vh; } /* height: 520px; */
.detail-modal .modal-title {margin-right: 70px;}
.detail-modal .modal-content {display: flex;}
.modal-content .content-side {flex: 1 1;}
.modal-content .info-item {margin-top: 20px; margin-bottom: 10px;}
.modal-content .info-item .info-title {font-size: 17px; font-weight: 700;}
.modal-content .info-item .info-content {margin-left: 10px; margin-top: 10px;}
.modal-content .info-item .info-content .sub-item {display: flex; margin: 8px; padding-left: 10px; padding-bottom: 3px; border-bottom: 1px solid #AAAAAA; width: 80%;}
.modal-content .info-item .info-content .sub-item .sub-label {width: 200px;}
.modal-content .info-item .info-content .sub-item .sub-value {color: #000066;}

.help-modal .modal-wrapper { width: 860px; height: 600px; }
.help-modal .modal-content { height: calc(100% - 60px); }
.help-modal .help-item {margin-top: 20px;}
.help-modal .help-item .help-title {font-size: 20px; font-weight: 700; margin-bottom: 5px;}
.help-modal .help-item .help-info {margin-left: 10px;}
.help-modal .help-item .help-info .help-value {margin: 5px 0px;}
.help-modal .help-item .help-info .sub-wrapper {margin-left: 10px;}
.help-modal .help-item .help-info .sub-wrapper .sub-item {margin: 3px 0px; display: flex; align-items: center;}
.help-modal .help-item .help-info .sub-wrapper .sub-item .point {width: 4px; height: 4px; border-radius: 50%; background-color: black; margin: 0px 7px;}
.help-modal .help-item .help-info .sub-wrapper .sub-item {font-size: 15px;}

.mobile .modal-back .modal-wrapper {height: auto; margin-bottom: 50px;}
.mobile .modal-back .modal-wrapper .modal-content {flex-direction: column; height: calc(100vh - 190px);}
.loading { background-color: rgba(0, 0, 0, 0.5); opacity: 0; transition: all 0.5s;}
.loading.show { opacity: 1; z-index: 2;}
.loading .loading-circle { position: absolute; top: calc(50% - 60px); left: calc(50% - 60px); border: 16px solid #f3f3f3; border-top: 16px solid #3437ee99; border-radius: 50%; width: 90px; height: 90px; -webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite; }
.loading .loading-circle.grey { border: 6px solid #444; border-top: 6px solid white; }

.loading .loading-label {font-size: 28px; color: white;}
.loading.active {z-index: 4;}

@-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }

@keyframes spin { 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }



