
body { overflow: hidden; margin: 0; padding: 0; }
.page-wrapper { width: 100vw; height: 100vh; background-position: center; overflow: hidden; }
.back-board { position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; opacity: 0; z-index: -1; overflow: hidden; background-color: white;}

.back-board.active { opacity: 1; z-index: 1; }

.flex {display: flex; align-items: center; justify-content: center;}
.flex-column {display: flex; flex-direction: column; align-items: center; justify-content: center;}

.loading { background-color: rgba(0, 0, 0, 0.5); opacity: 0; transition: all 0.5s;}
.loading.show { opacity: 1; z-index: 3;}
.loading .loading-circle { position: absolute; top: calc(50% - 60px); left: calc(50% - 60px); border: 16px solid #f3f3f3; border-top: 16px solid #3498db; border-radius: 50%; width: 90px; height: 90px; animation: spin 2s linear infinite; }
.loading .loading-circle.grey { border: 6px solid #444; border-top: 6px solid white; }

.loading .loading-label {font-size: 28px; color: white;}

@keyframes spin {
	0%   { transform: rotate(  0deg); }
	100% { transform: rotate(360deg); }
}
@keyframes arrowAnimate {
	0% 	 { transform:translateX( 30px); }
	100% { transform:translateX(-30px); }
}

.title {font-size: 20px; font-weight: 700;}
.big-title { font-size: 24px; font-weight: 700;}
.label { font-size: 14px;}
.capital {text-transform: capitalize;}

.button {background-color: #108CCA; font-size: 20px; border-radius: 8px; padding: 10px 30px; transition: all 0.2s; color: white; margin: 20px; cursor: pointer; text-align: center;}
.button.disable {cursor: default; opacity: 0.5;}
.button:hover {background-color: #123bc2;}

.scroll-y { padding-right: 5px; overflow-y: auto; overflow-x: hidden;}
.scroll-x { padding-bottom: 5px; overflow-x: auto; overflow-y: hidden;}
.scroll::-webkit-scrollbar { width: 6px; height: 3px; }
.scroll::-webkit-scrollbar-track { background: #DCDEDF; }
.scroll::-webkit-scrollbar-thumb { background: rgba(0, 0, 0, 0.8); }
.scroll::-webkit-scrollbar-thumb:hover { background: #BBB; }

.close-icon { position: absolute; top: 15px; right: 15px; width: 25px; height: 25px; border: 1px solid #525047; border-radius: 50%; cursor: pointer; background-color: rgba(255, 255, 255, 0.8);}
.close-icon svg { width: 13px; height: 13px; transition: transform 0.3s;}
/* .close-icon svg path { stroke: #525047;} */
.close-icon:hover svg { transform: rotate(-90deg); }

.size-alert {position: absolute; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 2; background-color: #FFFFFFDD;}
